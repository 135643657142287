import React from 'react'
import { AppBar } from '@material-ui/core'
import useLayout from '../assets/layout'
import colors from '../assets/colors'

export default function Header() {
  const l = useLayout()

  return <AppBar position="fixed" style={{ height: `${l.header.height}px`, backgroundColor: colors.light1, boxShadow: 'none', display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center' }}>
    <div style={{ height: `${l.header.logoSize.height}px`, width: '100%', position: 'fixed', top: `${(l.header.height - l.header.logoSize.height) / 2}px`, background: `url(/img/logo_288.png) no-repeat center/${l.header.logoSize.width}px ${l.header.logoSize.height}px` }}></div>
  </AppBar>
}