import { useRouteMatch } from 'react-router-dom'

export function useEMMatch<T extends EMMatch>() {
  const match = useRouteMatch<T>({
    exact: true,
    path: [
      "/",
      "/:state",
      "/:state/:pedido",
      "/:state/:pedido/:produto"
    ]
  })

  return match !== null
    ? { params: match.params }
    : undefined
}

export const stateTexts = {
  aguardando: 'Aguardando Pagamento',
  pagos: 'Novos Pagos',
  producao: 'Em Produção',
  enviados: 'Enviados'
}