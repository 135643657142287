import useMediaQuery from "@material-ui/core/useMediaQuery"

export default function useLayout() {
  const isBigPhoneScreen = useMediaQuery('(min-width:430px)')

  return {
    header: {
      height: isBigPhoneScreen ? 103 : 60,
      spaceBottom: isBigPhoneScreen ? 50 : 25,
      logoSize: {
        width: isBigPhoneScreen ? 288 : 144,
        height: isBigPhoneScreen ? 92 : 46
      },
      cartSize: isBigPhoneScreen ? 40 : 30
    },
    cardVerticalMargin: 13,
    cartPageColumns: [65, 16, 19],
    container: {
      maxWidth: 'sm' as 'sm',
      horizontal: {
        margin: 27,
        padding: 24
      }
    },
    defaultButton: {
      height: 55 + 6 * 2 + 5 * 2,
      verticalMargin: 25
    },
    pedidoCard: {
      width: 500,
      margin: 6
    }
  }
}