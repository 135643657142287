export default {
  primary: {
    extralight: '#f0fdff',
    light: '#a3dbe2',
    main: '#5dcddc',
    dark: '#18a4b7',
    extradark: '#088596'
  },
  secondary: {
    light: '#C1E1DD', // new
    main: '#17962d',
  },
  back: {
    light: '#ffc749',
    main: '#ffb203'
  },
  dark1: '#8F845B',
  dark2: '#AFAA97',
  dark3: '#5A5A5A',
  main1: '#0478ED',
  main2: '#56AAFF',
  secondary1: '#FBC702',
  light1: '#F1F1F1',
  light2: '#FDFDFD',
  red1: '#FF7171'
}