import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Dialog } from '@material-ui/core'
import useLayout from '../assets/layout'
import * as api from '../api'
import config from '../build/config.json'
import { useEMMatch, stateTexts } from './route'
import Header from './Header'
import ScrollToTop from './ScrollToTop'
import FullHeightContainer from './FullHeightContainer'
import PedidosPage from './PedidosPage'
import PedidoPage from './PedidoPage'
import MainPage from './MainPage'

const uploadsURL = config.baseURL + 'wp-content/uploads/'

export default function App() {
  const match = useEMMatch()
  const l = useLayout()
  const [processingOrders, setProcessingOrders] = useState<ProcessingOrders>()
  const stateKey = match?.params.state as undefined | keyof typeof stateTexts
  const stateText: string | undefined = stateTexts[stateKey!]
  const processingOrder = processingOrders?.[stateKey!]
  const pedido = processingOrder?.[+match?.params.pedido!]

  useEffect(() => {
    api.getProcessingOrders()
      .then(newProcessingOrders => {
        setProcessingOrders(newProcessingOrders)
      })
  }, [setProcessingOrders])

  return <FullHeightContainer style={{ display: 'flex', flexDirection: 'column', padding: '0', userSelect: 'none' }} maxWidth={l.container.maxWidth}>
    <ScrollToTop />
    <Header />
    <div style={{ marginTop: `${l.header.height + l.header.spaceBottom}px`, padding: '0 24px', flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      {match === undefined ||
        (match.params.state !== undefined && stateText === undefined) ||
        (match.params.pedido !== undefined && pedido === undefined) ||
        (match.params.produto !== undefined && pedido === undefined)
        ? <Redirect to="/" />
        : match.params.state === undefined &&
          match.params.pedido === undefined &&
          match.params.produto === undefined
          ? <MainPage processingOrders={processingOrders} />
          : processingOrders === undefined
            ? <p>Carregando...</p>
            : match.params.produto !== undefined
              ? <Dialog aria-labelledby="simple-dialog-title" open={true}>
                <img
                  alt="imagem da planta"
                  style={{ overflow: 'hidden', objectFit: 'cover' }}
                  src={uploadsURL + pedido!.items[+match.params.produto].srcimage[0]} />
              </Dialog>
              : match.params.pedido !== undefined
                ? <PedidoPage order={pedido!} />
                : <PedidosPage orders={processingOrder!} />
      }
    </div>
  </FullHeightContainer>
}