import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme =>
  createStyles({
    flexCentered: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    flexVerticalCentered: {
      display: 'flex',
      alignItems: 'center',
    }
  })
)