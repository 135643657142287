import React, { CSSProperties, useEffect, useState } from 'react'
import { Container } from '@material-ui/core'

export default function FullHeightContainer(p: {
  style?: CSSProperties
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined
  children: any
}) {
  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => window.addEventListener('resize', () => setHeight(window.innerHeight)), [])

  return <Container style={{ minHeight: height, ...p.style }} maxWidth={p.maxWidth}>
    {p.children}
  </Container>
}