import React from 'react'
import { Typography } from '@material-ui/core'
import StatusCard from './StatusCard'
import { stateTexts } from '../route'
import { NavLink } from 'react-router-dom'

export default function MainPage(p: {
  processingOrders?: ProcessingOrders
}) {
  return <>
    <Typography variant="h5" style={{ textAlign: 'center' }}>Pedidos</Typography>
    <div style={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      {Object.entries(stateTexts).map(([state, stateText]) =>
        <NavLink key={state} to={'/' + state} style={{ textDecoration: 'none', color: 'black', alignSelf: 'stretch' }}>
          <StatusCard name={`${stateText} (${p.processingOrders === undefined ? '?' : Object.keys(p.processingOrders[state as keyof typeof stateTexts]).length})`} />
        </NavLink>
      )}
    </div>
  </>
}