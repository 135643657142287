import React from 'react'
import Card from '@material-ui/core/Card'
import { Typography, CardActionArea } from '@material-ui/core'
import colors from '../../assets/colors'
import useStyles from '../util.s'
import useLayout from '../../assets/layout'
import { formatBRL } from '../util'
import useFitText from "use-fit-text"

const style = {
  flex: 1,
  fontFamily: 'signika',
  backgroundColor: colors.light1,
  textAlign: 'center'
} as const

export default function PedidoCard(p: {
  id: number
  order: Order
}) {
  const { fontSize: fontSize1, ref: ref1 } = useFitText({ maxFontSize: 180 })
  const { fontSize: fontSize2, ref: ref2 } = useFitText({ maxFontSize: 130 })
  const { fontSize: fontSize3, ref: ref3 } = useFitText({ maxFontSize: 130 })
  const c = useStyles()
  const { pedidoCard: { width, margin } } = useLayout()

  const shippingCost = p.order.shipping_cost > 0
    ? ' + ' + formatBRL(p.order.shipping_cost)
    : ''
  const discount = p.order.discount !== 0
    ? ' - ' + formatBRL(-p.order.discount)
    : ''

  return <Card style={{ margin: `3px ${margin}px` }}>
    <CardActionArea>
      <div style={{
        display: 'grid',
        color: 'black',
        width: `calc(100vw - ${margin}px)`,
        maxWidth: width + 'px',
        gridTemplate:
          `"id person" ${width * 0.1}px
"data shipping" ${width * 0.05}px
"prices prices" ${width * 0.05}px
/ 1fr 3fr`}}>
        <Typography className={c.flexCentered} style={{ ...style, fontSize: '2rem', gridArea: 'id' }}>{p.id}</Typography>
        <Typography className={c.flexCentered} ref={ref1} style={{ ...style, fontSize: fontSize1, gridArea: 'person', fontWeight: 700 }}>{p.order.person}</Typography>
        <Typography className={c.flexCentered} style={{ ...style, fontSize: '1rem', gridArea: 'data' }}>{p.order.data.replace(/^(\d{4})-(\d{2})-(\d{2}).+$/, '$3/$2/$1')}</Typography>
        <Typography className={c.flexCentered} ref={ref2} style={{ ...style, fontSize: fontSize2, gridArea: 'shipping' }}>{p.order.shipping_type}</Typography>
        <Typography className={c.flexCentered} ref={ref3} style={{ ...style, fontSize: fontSize3, gridArea: 'prices' }}>{`${formatBRL(p.order.price)}${shippingCost}${discount} = ${formatBRL(p.order.price + p.order.shipping_cost + p.order.discount)}`}</Typography>
      </div>
    </CardActionArea>
  </Card >
}