import React from 'react'
import { NavLink } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import ItemCard from './ItemCard'
import { useEMMatch } from '../route'

export default function PedidoPage(p: {
  order: Order
}) {
  const match = useEMMatch()
  
  return <>
    <Typography variant="h5" style={{ textAlign: 'center' }}>Pedido {+match?.params.pedido!}</Typography>
    <div style={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      {Object.entries(p.order.items).map(([id, item]: [string, Item]) =>
        <NavLink key={id} to={`/${match?.params.state}/${match?.params.pedido}/${id}`} style={{ textDecoration: 'none', color: 'black', alignSelf: 'stretch' }}>
          <ItemCard {...item} />
        </NavLink>
      )}
    </div>
  </>
}