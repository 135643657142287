import React from 'react'
import Card from '@material-ui/core/Card'
import { Typography, CardActionArea } from '@material-ui/core'
import useStyles from '../util.s'
import useLayout from '../../assets/layout'
import { formatBRL } from '../util'
import useFitText from "use-fit-text"

const style = {
  flex: 1,
  fontFamily: 'signika',
  backgroundColor: 'rgb(223 255 230)',
  textAlign: 'center'
} as const

export default function ItemCard(p: Item) {
  const { fontSize: fontSize1, ref: ref1 } = useFitText({ maxFontSize: 150 })
  const { fontSize: fontSize2, ref: ref2 } = useFitText({ maxFontSize: 150 })
  const c = useStyles()
  const { pedidoCard: { width, margin } } = useLayout()

  return <Card style={{ margin: `3px ${margin}px` }}>
    <CardActionArea>
      <div style={{
        display: 'grid',
        color: 'black',
        width: `calc(100vw - ${margin}px)`,
        maxWidth: width + 'px',
        gridTemplate:
          `"quantity product" ${width * 0.1333}px
"quantity prices" ${width * 0.0666}px
/ 1fr 3fr`}}>
        <Typography className={c.flexCentered} style={{ ...style, fontSize: '2rem', gridArea: 'quantity' }}>{`${p.quantity}x`}</Typography>
        <Typography className={c.flexCentered} ref={ref1} style={{ ...style, fontSize: fontSize1, gridArea: 'product', fontWeight: 700 }}>{p.product}</Typography>
        <Typography className={c.flexCentered} ref={ref2} style={{ ...style, fontSize: fontSize2, gridArea: 'prices' }}>{formatBRL(p.price)}</Typography>
      </div>
    </CardActionArea>
  </Card >
}