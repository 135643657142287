import React from 'react'
import Card from '@material-ui/core/Card'
import { Typography, CardActionArea } from '@material-ui/core'
import colors from '../../assets/colors'
import useStyles from '../util.s'
import useLayout from '../../assets/layout'

export default function StatusCard(p: {
  name: string
}) {
  const c = useStyles()
  const { pedidoCard: { width, margin } } = useLayout()

  return <Card style={{ margin: `3px ${margin}px` }}>
    <CardActionArea>
      <div style={{ display: 'flex', color: 'black', height: width * 0.2 + 'px', width: `calc(100vw - ${margin}px)`, maxWidth: width + 'px' }}>
        <Typography className={c.flexCentered} style={{ flex: 1, fontFamily: 'signika', fontSize: '1.9rem', backgroundColor: colors.secondary.light }}>{p.name}</Typography>
      </div>
    </CardActionArea>
  </Card >
}