import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import PedidoCard from './PedidoCard'
import { stateTexts, useEMMatch } from '../route'
import { formatBRL } from '../util'

interface Totals {
  sells: number
  freight: number
  freightJADLOG: number
  freightPAC: number
  freightSEDEX: number
  freightNotIdentified: number
}

export default function PedidosPage(p: {
  orders: Orders
}) {
  const match = useEMMatch()
  const stateKey = match?.params.state as undefined | keyof typeof stateTexts
  const [totals, setTotals] = useState<Totals>()

  useEffect(() => {
    const newTotals = {
      sells: 0,
      freight: 0,
      freightJADLOG: 0,
      freightPAC: 0,
      freightSEDEX: 0,
      freightNotIdentified: 0
    }

    for (const order of Object.values(p.orders)) {
      newTotals.sells += order.price + order.discount
      newTotals.freight += order.shipping_cost
      if (order.shipping_type?.match(/\bjad[ -]*log\b/gi)) {
        newTotals.freightJADLOG += order.shipping_cost
      } else if (order.shipping_type?.match(/\bpac\b/gi)) {
        newTotals.freightPAC += order.shipping_cost
      } else if (order.shipping_type?.match(/\bsedex\b/gi)) {
        newTotals.freightSEDEX += order.shipping_cost
      } else {
        newTotals.freightNotIdentified += order.shipping_cost
      }
    }
    setTotals(newTotals)
  }, [p.orders])

  return <>
    <Typography variant="h5" style={{ textAlign: 'center' }}>{stateTexts[stateKey!]}</Typography>
    <div style={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
      {Object.entries(p.orders).map(([id, order]: [string, Order]) =>
        <NavLink key={id} to={`/${match?.params.state!}/${id}`} style={{ textDecoration: 'none', color: 'black', alignSelf: 'stretch' }}>
          <PedidoCard id={+id} order={order} />
        </NavLink>
      )}
      {stateKey === 'producao' && totals !== undefined &&
        <div style={{ margin: '10px' }}>
          <Typography variant="h5">Total vendas (sem frete): {formatBRL(totals.sells)}</Typography>
          <Typography variant="h5">Total frete: {formatBRL(totals.freight)}</Typography>
          <Typography variant="h6">Total JADLOG: {formatBRL(totals.freightJADLOG)}</Typography>
          <Typography variant="h6">Total Correios (PAC + SEDEX): {formatBRL(totals.freightPAC + totals.freightSEDEX)}</Typography>
          <Typography variant="h6">Total PAC: {formatBRL(totals.freightPAC)}</Typography>
          <Typography variant="h6">Total SEDEX: {formatBRL(totals.freightSEDEX)}</Typography>
          {totals.freightNotIdentified > 0 &&
            <Typography variant="h6">Total Não identificado: {formatBRL(totals.freightNotIdentified)}</Typography>
          }
        </div>
      }
    </div>
  </>
}