import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { CssBaseline, createMuiTheme, ThemeProvider } from '@material-ui/core'
// import store from './redux'
import colors from './assets/colors'
import Components from './Components'

const theme = createMuiTheme({
  palette: {
    primary: colors.primary,
    secondary: colors.secondary
  }
})

export default function App() {
  return <ThemeProvider theme={theme}>
    <CssBaseline />
    {/* <Provider store={store}> */}
      <Router>
        <Switch>
          <Components />
        </Switch>
      </Router>
    {/* </Provider> */}
  </ThemeProvider>
}